/****
 *  Usage
 *  var lgConfig= {
 *      appId: "Account ID",
 *      apiKey: "Provider Key",
 *      <optional> form: "Form Key",
 *
 *  }
 * var lgCustom= {
 *     onResponse: function(){
 *          // Action
 *     }
 * }
 * 
 * var stepsConfig = { 
 *      "countSteps": "Total steps",
 *      "currentStep": " Current step" 
 * };
 * @param Config
 * @returns {{formKey: (*|string)}}
 */

import { Loader } from "@/modules/loader/index";
import { LeadgreaseSingleForm } from "@/modules/form/singleForm";
import { LeadgreaseLayerForm } from "@/modules/form/layerForm";
import { LeadgreaseFormSwiper } from "@/modules/form/formSwiper";
import { LeadgreaseEndpointsV2 } from "@/modules/api/endpoints/index";
import { LeadgreaseEndpointsV1 } from "@/modules/api/endpoints/index";
import 'bootstrap';

export class Leadgrease {
    constructor({ lgConfig, lgCustom, lgPixels, stepsConfig, apiVersion, accountCode }) {
        console.log("Leadgrease: Building")

        this.AfterCompletionNothing = 0;
        this.AfterCompletionRedirect = 1;
        this.AfterCompletionThankyouPage = 2;

        this.lgConfig = lgConfig;
        this.appId = this.lgConfig.appId;
        this.isDev = this.lgConfig.isDev;
        this.lgCustom = lgCustom;
        this.lgPixels = lgPixels;
        this.stepsConfig = stepsConfig;
        this.Loader = new Loader();
        this.apiVersion = apiVersion;
        this.EndPoints = (apiVersion != 2) ? new LeadgreaseEndpointsV1() : new LeadgreaseEndpointsV2(accountCode)
        this.Forms = [];
        this.Form = null;
        this.queryParams = this.getQueryParamsFromUrl(location);
        this.timeRedirect = 3000;
        this.init();
    }

    async init() {
        console.log("Leadgrease: Starting")
        this.Loader.show();
        this.autocomplete();

        let form = document.querySelector('form');
        if (form)
            this.Form = await this.initForm(form, {
                ...this.getConfiguration(),
                apiKey: this.apiKey,
                formKey: this.formKey,
            });
        this.Loader.hide();

        this.redirectWhenDontHaveForm();
    }

    async initForm(el, config) {
        let lgForm;
        if (el.getAttribute('data-slg-type') == "C-FORM-LAYERS") {
            lgForm = new LeadgreaseLayerForm(el, config, this);
        } else if ((el.getAttribute('data-slg-type') == "C-FORM-SWIPER")) {
            lgForm = new LeadgreaseFormSwiper(el, config, this);
        } else {
            lgForm = new LeadgreaseSingleForm(el, config, this);
        }
        await lgForm.init();
        this.Forms.push(lgForm);
        return lgForm;
    }

    getConfiguration() {
        return {
            lgConfig: this.lgConfig,
            lgCustom: this.lgCustom,
            lgPixels: this.lgPixels,
            stepsConfig: this.stepsConfig
        }
    }

    autocomplete() {
        let textAutocomplete = document.querySelectorAll("[data-lg-autocomplete]");
        Array.from(textAutocomplete).forEach((element) => {
            for (const key in this.queryParams) {
                element.innerHTML = element.innerHTML.replace(`{{${key}}}`, this.queryParams[key]);
            }
        })
    }

    redirectWhenDontHaveForm() {
        if (
            ((!this.Form && this.Forms.length == 0) || (this.Form.hasFields() == false))
            && (this.AfterCompletionRedirect == this.lgConfig.afterCompletion)
            && this.lgConfig.redirectUrl
        ) {
            setTimeout(() => { this.redirect(this.lgConfig.redirectUrl); }, this.timeRedirect);
        }
    }

    getQueryParamsFromUrl(url) {
        let parseUrl = new URL(url);
        return Object.fromEntries(new URLSearchParams(parseUrl.search));
    }
    getUtmQueryParams() {
        let utmParams = {};
        for (let param in this.queryParams) {
            if (param.includes('utm')) {
                utmParams[param] = this.queryParams[param];
            }
        }
        return utmParams;
    }
    handleQueryParams(oldQueryParams, newQueryParams) {
        for (let newParam in newQueryParams) {
            for (let oldParam in oldQueryParams) {
                if (`{{${oldParam}}}` == newQueryParams[newParam]) {
                    newQueryParams[newParam] = oldQueryParams[oldParam];
                }
            }
        }

        return newQueryParams;

    }
    redirect(url, queryParams = {}) {

        // if(!queryParams) queryParams = {};

        let redirectQueryParams = this.getQueryParamsFromUrl(url);
        let completeRedirectQueryParams = this.handleQueryParams(this.queryParams, redirectQueryParams);
        let utmQueryParams = this.getUtmQueryParams();
        queryParams = {
            ...completeRedirectQueryParams,
            ...queryParams,
            ...utmQueryParams
        }
        // console.log(queryParams);
        let newUrl = new URL(url);
        newUrl.search = new URLSearchParams(queryParams).toString();
        window.location.assign(newUrl);
    }
}